import React from "react"
import Layout from "../components/layout"
import Link from "../components/link"
import { ButtonLink, SubmitButton } from "../components/button"
import { Row, FullRow, Shim, PageTitle, FlexItem } from "../components/row"
import { Label, RequiredLabel, InlineLabel } from "../components/forms"
import { Box } from "../components/box"
import SEO from "../components/seo"

import bgFloralWhite from "../images/ui/bg_floral_white.jpg"

const pageTitle = "Contact"

export default () => (
  <Layout>
    <SEO title={pageTitle} />
    <Row>
      <PageTitle>{pageTitle}</PageTitle>

      <Shim flex>
        <Box className="gold">
          <h2 className="title">Tower District</h2>
          <address style={{ margin: "0", lineHeight: "1.5" }}>
            1292 N. Wishon, Fresno, CA 93728
            <br />
            (559) 917-8880
            <br />
            <Link to="https://www.google.com/search?hl=en&q=1292%20N.%20Wishon%20Ave.%20Fresno%20CA">
              Directions
            </Link>
          </address>
        </Box>
        <Box className="gold">
          <h2 className="title">Bullard &amp; West</h2>
          <address style={{ margin: "0", lineHeight: "1.5" }}>
            2025 W. Bullard, Fresno, CA 93711
            <br />
            (559) 455-8979
            <br />
              <Link to="https://www.google.com/search?hl=en&q=2025%20W.%20Bullard%20Ave.%20Fresno%20CA">
              Directions
            </Link>
          </address>
        </Box>

      </Shim>
      <Shim flex>
        <Box className="gold">
          <h2 className="title">Hours*</h2>
          <p style={{ margin: "0" }}>
            Tuesday - Saturday 10am - 5pm
            <br />
            <em>Closed on Sunday &amp; Monday</em>
          </p>
        </Box>
      </Shim>

      <Shim style={{ textAlign: "center" }}>
        <p style={{ fontSize: "80%" }}>
          * Hours are subject to change for holidays and season. Please also
          check our{" "}
          <a href="https://goo.gl/maps/FDa4BQ9eybW3DdKf6">Google listing</a>.
        </p>
      </Shim>

      <Shim>
        <form
          action="https://formspree.io/info@frostedcakery.com"
          name="contact"
          method="post"
        >
          <RequiredLabel for="name">
            Name <span>(required)</span>
          </RequiredLabel>
          <input type="text" name="name" required="required" />
          <input type="text" name="_gotcha" style={{ display: "none" }} />
          <RequiredLabel for="phone">
            Phone <span>(required)</span>
          </RequiredLabel>
          <input type="tel" name="phone" required="required" />
          <RequiredLabel for="email">
            Email <span>(required)</span>
          </RequiredLabel>
          <input type="email" name="email" required="required" />

          <Label for="eventDate">Event date</Label>
          <input type="text" name="eventDate" />

          <Label for="message">Message</Label>
          <textarea name="message" />
          <input
            type="hidden"
            name="_subject"
            value="New contact from website from frostedcakery.com"
          />
          <input
            type="hidden"
            name="_next"
            value="https://frostedcakery.com/submitted"
          />
          <input type="checkbox" name="acknowledgement" id="acknowledgement" value="YES" required="required" />
          <RequiredLabel style={{ display: 'inline' }} for="acknowledgement">&nbsp;<span>(required)</span> I understand that submitting this form does not confirm an order request or consultation. I acknowledge that I must first confirm by phone or in-person with a Frosted representative that my order or consultation has been received.</RequiredLabel>
          <p>
            <SubmitButton type="submit" data-title="Send message">
              Send message
            </SubmitButton>
          </p>
        </form>
      </Shim>
    </Row>
  </Layout>
)
