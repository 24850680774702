import React from "react"
import { Link } from "gatsby"
import styled, { css } from "styled-components"
import CSS from "../config/css"

const Label = styled.label`
  display: block;
  margin-top: 8px;
  font-weight: normal;
`
const InlineLabel = styled(Label)`
  display: inline-block;
  margin: 4px 0;
`

const RequiredLabel = styled(Label)`
  span {
    background-color: ${CSS.colors.lightmint};
  }
`

export { Label, InlineLabel, RequiredLabel }
